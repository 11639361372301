@mixin phase($prefix, $property) {
	#{$prefix} {
		// export declare const LeadStatuses: readonly ["pending", "approved", "declined", "active", "expired"];
		&-lead {
			&-expired {
				#{$property}: $near-black;
			}
			&-archived,
			&-pending,
			&-na {
				#{$property}: $inactive-text-gray;
			}
			&-approved {
				#{$property}: $action-color;
			}
			&-active {
				#{$property}: $success-color;
			}
			&-declined {
				#{$property}: $warning-color;
			}
		}

		// export declare const OpportunityStatuses: readonly ["pending", "active", "archived"];
		&-opportunity {
			&-archived,
			&-pending,
			&-na {
				#{$property}: $inactive-text-gray;
			}
			&-active {
				#{$property}: $action-color;
			}
		}

		// export declare const AccountStatuses: readonly ["invited", "pending", "approved", "active", "declined", "suspended"];
		&-account {
			&-approved {
				#{$property}: $near-black;
			}
			&-pending,
			&-invited,
			&-na {
				#{$property}: $inactive-text-gray;
			}
			&-active {
				#{$property}: $action-color;
			}
			&-declined,
			&-suspended {
				#{$property}: $warning-color;
			}
		}

		// export declare const PartnerOrganizationStatuses: readonly ["pending", "active", "declined", "suspended"];
        &-partner-organization {
            &-pending,
            &-na {
                #{$property}: $inactive-text-gray;
            }
            &-active {
                #{$property}: $action-color;
            }
            &-declined,
            &-suspended {
                #{$property}: $warning-color;
            }
        }

		// export declare const PaymentStatuses: readonly ["pending", "scheduled", "processing", "complete", "error", "canceled", "denied", "failed", "refunded", "returned", "unclaimed", "held"];
		// export declare const PaymentAttemptStatuses: readonly ["processing", "complete", "error", "canceled", "denied", "failed", "refunded", "returned", "unclaimed", "held"];
		&-payment,
		&-payment-attempt {
			&-refunded,
			&-returned,
			&-unclaimed,
			&-held {
				#{$property}: $near-black;
			}
			&-pending,
			&-na {
				#{$property}: $inactive-text-gray;
			}
			&-processing {
				#{$property}: $neutral-gray;
			}
			&-complete {
				#{$property}: $success-color;
			}
			&-error,
			&-canceled,
			&-denied,
			&-failed {
				#{$property}: $warning-color;
			}
			&-scheduled {
				#{$property}: $brand-color2;
			}
		}
	}
}

@include phase('.status', 'color');
@include phase('.status-bg', 'background-color');
