@import 'variables', 'mixins';

.btn {
	font-weight:normal;
	&.btn-clear {
		background-color:transparent;
	}
	&.btn-more {
		text-transform: capitalize;
	}
}

.modal-body {
	padding: 30px;
	.modal-title {
		font-size: 24px;
	}

	.body-row {
		margin-bottom:0;
		> div {
			margin-bottom:0 !important;
		}
	}

	.footer-row {
		margin-bottom:0;
		> div {
			margin-bottom:0 !important;
		}
		.btns {
			margin-left:auto;
			margin-right:auto;
			width:16rem;
		}
	}



	.modal-info-label {
		h4 {
			color: $gray-900;
			margin:0;
		}
	}
}

.clearfix {
	clear:both;
	&:after {
		content:"";
		display:table;
		clear:both;
	}
}

.child-row {
	margin-left:15px;
	margin-right:15px;
}

.payments, .payments .trow > div, .payment {
	.completed {
		font-weight: normal;
		color: $green;
		&.amount {
			color: $green;
		}
	}
	.due, .pending {
		font-weight: normal;
		color: $orange;
	}
	.amount {
		cursor: pointer;
		text-decoration: none;
		font-weight: normal;
	}
}

.table .trow div {
	outline: none;
}

.card {
	.line {
		margin: 0 1.875rem;
	}
	.card-header {
		padding: 1.875rem 1.875rem 0.5rem;
		background: #fff;
	}
	.card-body {
		padding: 0.5rem 1.875rem 1.875rem;

		&.table-container {
			padding-left: 0;
			padding-right: 0;
		}
	}
	h2 {
		margin-bottom: 0;
	}
	p:last-of-type {
		margin: 0;
	}
}

.form-control-second {
	margin-top: 10px;
}

form .form-section {
	&:not(:last-of-type) {
		margin-bottom: 30px;
	}
	.form-group {
		.form-control, .custom-select, editor, p {
			width: 100%;
		}
	}
	@media (min-width: $sm-min-width) {
		.form-group {
			.form-control, .custom-select, editor, p {
				max-width: 400px;
				margin-left: auto;
				display: block;
			}
		}
	}
}

.row > div[class^="col"] {
	margin-bottom: $grid-gutter-width;
}

form .row > div[class^="col"] {
	margin-bottom: 0;
}

.table {
	.thead .trow {
		border: 0 none;
		> div {
			font-size: 18px;
			font-weight: 700;
			&.sortable {
				cursor: pointer;
			}
			@media (max-width: 1600px) and (min-width: 1200px) {
				font-size: 14px;
			}
		}
	}
	.thead.bg-thead {
		background: rgba($info, 0.25);
	}
	.trow {
		display: table;
		width: 100%;
		padding: 10px 0;
		border-bottom: 1px solid $gray-300;
		@media (min-width: 1200px) {
			> div {
				display: table-cell;
				vertical-align: top;
				&:not([class*=col]) {
					width: 15.5%;
				}
				padding-right: 10px;
				&:last-of-type:not([class*=col]) {
					width: 7%;
				}
				.svg-wrap {
					width: 18px;
					display: inline-block;
					svg {
						width: 100%;
					}
				}
			}
		}
		> div, > div a, > div button {
			word-wrap: break-word;
			word-break: break-word;
			@media (max-width: 1600px) and (min-width: 1200px) {
				font-size: 12px;
			}
		}
	}
}
table.table {
	@media (max-width: 1199px) {
		display: block;
		thead.thead, tbody.tbody, tr.trow {
			display: block;
			td, th {
				border-width: 0;
				display: block;
			}
		}
		thead.thead {
			display: none;
		}
	}
	@media (min-width: 1200px) {
		thead.thead, tbody.tbody {
			display: table-row-group;
			&.font-sm {
				font-size: 0.875rem;;
			}
		}
		tr.trow {
			display: table-row;
		}
	}
}

.pagination-wrap {
	span {
		font-size: 14px;
	}
	.pagination {
		display: inline-flex;
		.page-item {
			margin: 0 3px;
			.page-link {
				font-size: 14px;
			}
		}
	}
}

.ng-dirty.input-error {
	color: $red;
}

.text-input {
	border-color:$gray-300;
	// &:-moz-placeholder {
	// 	color: $gray-900;
	// }
	// &::-webkit-input-placeholder {
	// 	color: $gray-900;
	// }
	// &::-moz-placeholder {
	// 	color: $gray-900;
	// }
	// &:-ms-input-placeholder {
	// 	color: $gray-900;
	// }
	// &::-ms-input-placeholder {
	// 	color: $gray-900;
	// }
	// &::placeholder {
	// 	color: $gray-900;
	// }
}

.text-business {
	color: $business;
}

.load-more button {
	color: $gray-400;
	background-color: transparent;
}


.row > div[class^="col"] .card {
	height: 100%;
}

.page-item.active .page-link {
	color: #fff !important;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 7px;
}
::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: lightgrey;
}
