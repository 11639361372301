@function generate-angles($increments) {
	$angles: ();
	$num-steps: 360 / $increments;

	@for $i from 0 through $num-steps {
		$angle: $i * $increments;
		$angles: append($angles, $angle);
	}

	@return $angles;
}

$angles: generate-angles(45);

@each $angle in $angles {
	.ppicon-rotate-#{$angle} {
		transform: rotate(#{$angle}deg);
	}
}
