// Logged in pages global styles
@import "assets/stylesheets/logged-in-pages.scss";
@import "applibrary/styles";

// Nabar
.navbar-dark .navbar-collapse .nav-link {
	color: $navbar-dark-color;
	&:hover {
		color: $navbar-dark-hover-color;
	}
	&:active,
	&.active {
		color: $navbar-dark-active-color;
	}
	&.disabled {
		color: $navbar-dark-disabled-color;
	}
}

app-redirect {
	display: block;
	width: 100%;
	height: 100%;
}

// Override the dynamic styles for the accept cookies button.
div#hs-eu-cookie-confirmation
	div#hs-eu-cookie-confirmation-inner
	a#hs-eu-confirmation-button {
	color: #fff !important;
}

.announcements .announcement:not(:last-of-type) {
	margin-bottom: 0.75rem !important;
	padding-bottom: 0.75rem;
	border-bottom: 1px solid $gray-300;
}

body {
	background: $bg-color;
	color: $body-color;
}

html, body {
	font-family: $body-copy;
	font-weight: $body-copy-weight;
	text-transform: $body-copy-transform;
	-webkit-text-decoration: $body-copy-decoration;
	        text-decoration: $body-copy-decoration;
}

h1, h2 {
	font-family: $header-font;
	font-weight: $header-font-weight;
	text-transform: $header-font-transform;
	-webkit-text-decoration: $header-font-decoration;
	        text-decoration: $header-font-decoration;
}

h3, h4, h5, h6 {
	font-family: $sub-head-font;
	font-weight: $sub-head-font-weight;
	text-transform: $sub-head-font-transform;
	-webkit-text-decoration: $sub-head-font-decoration;
	        text-decoration: $sub-head-font-decoration;
}

a {
	font-family: $link-font;
	font-weight: $link-font-weight;
	text-transform: $link-font-transform;
	-webkit-text-decoration: $link-font-decoration;
	        text-decoration: $link-font-decoration;
}

button:not(.ignore-button-fonts), .btn, a.btn {
	font-family: $button-font;
	font-weight: $button-font-weight;
	text-transform: $button-font-transform;
	-webkit-text-decoration: $button-font-decoration;
	        text-decoration: $button-font-decoration;
}

.force-square {
	width: 100%;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

/**
 * Use this when you need a Full Screen modal
 *
 * @see {@link NgbModalOptions.windowClass}
 */
.full-screen-modal-window {
	height: 100%;
	margin: 0px;
}

/**
 * Use this when you need a Full Screen modal
 *
 * @see {@link NgbModalOptions.modalDialogClass}
 */
 .full-screen-modal-dialog {
	height: 100%;
	margin: 0px;
	&.modal-dialog-centered {
		margin: 0px;
	}

	.modal-content {
		height: 100%
	}
}

// TODO: move to applibrary
@import "./assets/stylesheets/inset-card";

a {
	&.disabled {
		&, &:not(.btn):not(.page-link):hover {
			color: $inactive-text-gray;
			cursor: default;
		}
	}
}

fa-icon  {
	svg.fa-question-circle {
		cursor: pointer;
		color: $brand-color1;
	}
}

.ppio-actionbuttondirective-loading {
	font-size: 0.0001px;
	& fa-icon {
		font-size: $btn-font-size;
	}
}

.btn:disabled {
	cursor: not-allowed;
}

.plan-hidden {
	pointer-events: none;
	user-select: none;

	&>* {
		filter: blur(5px);
	}
}

.rc-icon-holder {
	background-color: color-mix(in srgb, var(--brand-color1) 15%, transparent);
	height: 3.5rem;
	width: 3.5rem;
	min-width: 3.5rem;
	aspect-ratio: 1;
	border-radius: 0.25rem;
	.ppicon, fa-icon {
		font-size: 1.5rem!important;
		line-height: 1;
		&-link {
			font-size: 1rem!important;
		}
	}
}

.text-decoration-underline {
	text-decoration: underline!important;
}

.text-black {
	color: #000;

	&:hover {
		color:#000 !important;
	}
}


.ppio-spinner-backdrop {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3); /* Adjust the color and opacity as needed */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10; /* Ensure it appears above the content */
}

.ppio-spinner {
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-left-color: #000;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	animation: spin .8s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
