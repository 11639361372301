@use "@ag-grid-community/styles" as ag;
@include ag.grid-styles((theme: material));

.ag-theme-material {
	.ag-header-cell {
		background-color: #F5F7FB;
		color: #000;
	}
	.ag-layout-auto-height {
		.ag-center-cols-container, .ag-center-cols-viewport {
			min-height: 45px;
		}
	}
	--ag-odd-row-background-color: #fff;
	--ag-odd-row-background-hover-color: red;
	--ag-material-active-color: rgb(8, 235, 8);
	--ag-range-selection-border-style: #C5C5C5;
	// --ag-selected-row-background-color: rgba(255, 0, 0, 0.1);
	--ag-header-cell-hover-background-color: #F9FBFF;
	--ag-column-hover-color: rgb(8, 235, 8);
	--ag-input-focus-border-color: rgb(5, 156, 5);
	--ag-selected-row-background-color: rgb(0, 255, 0, 0.1);
	// --ag-range-selection-background-color: rgba(255, 0, 0, 0.2);
	// --ag-range-selection-background-color-2: rgba(255, 0, 0, 0.36);
	// --ag-range-selection-background-color-3: rgba(255, 0, 0, 0.488);
	// --ag-range-selection-background-color-4: rgba(255, 0, 0, 0.5904);
}
