@import "dependencies";

html {
	// see https://dev.to/nicm42/combining-sass-variables-with-css-custom-properties-5hj7
	--metrics-hierarchy1: #{$brand-color1};
	--metrics-hierarchy2: #{$brand-color3};
	--metrics-hierarchy3: #{$action-color};
	--metrics-hierarchy4: #{$success-color};
	--metrics-hierarchy5: #{$brand-color2};
	--metrics-hierarchy6: #{$brand-color4};
	--metrics-hierarchy7: #{$neutral-gray};
}
