// Upload modal styling
.upload-modal .modal-dialog {
	max-width: 445px !important;
}

// Choose plan modal styles
.choose-plan {
	.modal-dialog {
		max-width: 52rem !important;
	}
	.modal-content {
		background: #F6F9FC;
		.modal-header {
			border: 0 none;
			padding: 25px 30px 0;
		}
		.modal-body {
			padding-bottom: 0.5rem;
			padding-top: 1rem;
		}
	}
	// Styles for the tooltip in the choose plan popup.
	.tier-card .tooltip {
		.arrow::before {
			border-top-color: #000 !important;
		}
		.tooltip-inner {
			background: #000 !important;
			color: #fff !important;
		}
	}
}
.terms-modal .modal-body {
	max-height: 500px;
	overflow-y: scroll;
}
