@import "variables";

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-Thin.eot');
	src: url($fonts-url+'overpass/Overpass-Thin.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-Thin.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-Thin.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-Thin.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-Thin.svg?#Overpass-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-ThinItalic.eot');
	src: url($fonts-url+'overpass/Overpass-ThinItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-ThinItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-ThinItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-ThinItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-ThinItalic.svg?#Overpass-ThinItalic') format('svg');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-ExtraLight.eot');
	src: url($fonts-url+'overpass/Overpass-ExtraLight.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-ExtraLight.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-ExtraLight.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-ExtraLight.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-ExtraLight.svg?#Overpass-ExtraLight') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-ExtraLightItalic.eot');
	src: url($fonts-url+'overpass/Overpass-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-ExtraLightItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-ExtraLightItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-ExtraLightItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-ExtraLightItalic.svg?#Overpass-ExtraLightItalic') format('svg');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-Light.eot');
	src: url($fonts-url+'overpass/Overpass-Light.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-Light.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-Light.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-Light.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-Light.svg?#Overpass-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-LightItalic.eot');
	src: url($fonts-url+'overpass/Overpass-LightItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-LightItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-LightItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-LightItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-LightItalic.svg?#Overpass-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-Regular.eot');
	src: url($fonts-url+'overpass/Overpass-Regular.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-Regular.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-Regular.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-Regular.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-Regular.svg?#Overpass-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-Italic.eot');
	src: url($fonts-url+'overpass/Overpass-Italic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-Italic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-Italic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-Italic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-Italic.svg?#Overpass-Italic') format('svg');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-SemiBold.eot');
	src: url($fonts-url+'overpass/Overpass-SemiBold.eot') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-SemiBold.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-SemiBold.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-SemiBold.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-SemiBold.svg?#Overpass-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-SemiBoldItalic.eot');
	src: url($fonts-url+'overpass/Overpass-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-SemiBoldItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-SemiBoldItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-SemiBoldItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-SemiBoldItalic.svg?#Overpass-SemiBoldItalic') format('svg');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-Bold.eot');
	src: url($fonts-url+'overpass/Overpass-Bold.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-Bold.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-Bold.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-Bold.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-Bold.svg?#Overpass-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-BoldItalic.eot');
	src: url($fonts-url+'overpass/Overpass-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-BoldItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-BoldItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-BoldItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-BoldItalic.svg?#Overpass-BoldItalic') format('svg');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-ExtraBold.eot');
	src: url($fonts-url+'overpass/Overpass-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-ExtraBold.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-ExtraBold.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-ExtraBold.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-ExtraBold.svg?#Overpass-ExtraBold') format('svg');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-ExtraBoldItalic.eot');
	src: url($fonts-url+'overpass/Overpass-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-ExtraBoldItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-ExtraBoldItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-ExtraBoldItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-ExtraBoldItalic.svg?#Overpass-ExtraBoldItalic') format('svg');
	font-weight: 800;
	font-style: italic;
}


@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-Black.eot');
	src: url($fonts-url+'overpass/Overpass-Black.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-Black.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-Black.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-Black.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-Black.svg?#Overpass-Black') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Overpass';
	src: url($fonts-url+'overpass/Overpass-BlackItalic.eot');
	src: url($fonts-url+'overpass/Overpass-BlackItalic.eot?#iefix') format('embedded-opentype'),
	url($fonts-url+'overpass/Overpass-BlackItalic.woff2') format('woff2'),
	url($fonts-url+'overpass/Overpass-BlackItalic.woff') format('woff'),
	url($fonts-url+'overpass/Overpass-BlackItalic.ttf') format('truetype'),
	url($fonts-url+'overpass/Overpass-BlackItalic.svg?#Overpass-BlackItalic') format('svg');
	font-weight: 900;
	font-style: italic;
}
