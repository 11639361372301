$font-overpass: 'Overpass';

$header-font: $font-overpass;
$header-font-weight: bold;
$header-font-transform: none;
$header-font-decoration: none;

$sub-head-font: $font-overpass;
$sub-head-font-weight: bold;
$sub-head-font-transform: none;
$sub-head-font-decoration: none;

$body-copy: $font-overpass;
$body-copy-weight: normal;
$body-copy-transform: none;
$body-copy-decoration: none;

$link-font: $font-overpass;
$link-font-weight: normal;
$link-font-transform: none;
$link-font-decoration: none;

$button-font: $font-overpass;
$button-font-weight: normal;
$button-font-transform: uppercase;
$button-font-decoration: none;
