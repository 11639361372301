@mixin phase($prefix, $property, $suffix: '') {
	#{$prefix} {
		&-lead {
			&-na#{$suffix} {
				#{$property}: $inactive-text-gray;
			}
			&-processing#{$suffix} {
				#{$property}:  $neutral-gray;
			}
			&-prospecting#{$suffix} {
				#{$property}:  $brand-color3;
			}
			&-consulting#{$suffix} {
				#{$property}:  $brand-color2;
			}
			&-proposed#{$suffix} {
				#{$property}:  $action-color;
			}
			&-won#{$suffix} {
				#{$property}:  $success-color;
			}
			&-lost#{$suffix} {
				#{$property}:  $brand-color4;
			}
		}
		&-opportunity {
			&-na,
			&-pending#{$suffix} {
				#{$property}:  $inactive-text-gray;
			}
			&-engaged#{$suffix} {
				#{$property}:  $action-color;
			}
			&-interested#{$suffix} {
				#{$property}:  $brand-color2;
			}
			&-won#{$suffix} {
				#{$property}:  $success-color;
			}
			&-declined#{$suffix} {
				#{$property}:  $warning-color;
			}
			&-lost#{$suffix} {
				#{$property}:  $brand-color4;
			}
		}
	}
}

@include phase('.phase', 'color');
@include phase('.phase', 'color', ':focus');
@include phase('.phase', 'color', ':hover');
@include phase('.phase', 'color', ':active');

@include phase('.phase-bg', 'background-color');
