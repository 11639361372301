@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@ag-grid-community/styles/ag-theme-material.min.css";

@import "./forms";
@import "./components";
@import "./fonts";
@import "./layout";
@import "./features";
@import "./links";
@import "./ppio-bootstrap-branding";


// Global Styles
html,
body {
	min-height: 100%;
	font-family: Overpass, Arial;
}

.container-fluid {
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
}

// Form Styles
form .alert {
	margin-top: 10px;
}

.text-red {
	color: $red;
}

.text-underline {
	text-decoration: underline;
}

.image-wrap {
	img {
		display: inline-block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	.row .col-md-6 .card {
		height: auto;
	}
}

input,
select {
	background-color: $white;
	border-color: $gray-300;
}

fa-icon {
	display: inline-block;
}

// Custom Check Styling
.custom-check-label {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 0;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 24px;
	input {
		position: absolute;
		opacity: 0;
		width: 1px;
		height: 1px;
		margin: 0;
		&:checked {
			~ .checkmark {
				border-color: $action-color;
				&:after {
					display: block;
				}
			}
		}
		&:disabled {
			cursor: arrow;
			~ .checkmark {
				border-color: $inactive-text-gray;
				&::after {
					border-color: $inactive-text-gray;
				}
			}
			~ .check-text {
				color: $inactive-text-gray;
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 2px solid;
		border-radius: 2px;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 5px;
			top: 1px;
			width: 7px;
			height: 13px;
			border: solid $action-color;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	// &:hover input ~ .checkmark {
		// background-color: $gray-300;
	// }
	// &:hover input:disabled ~ .checkmark {
	// 	background: none;
	// }
}

.question-circle {
	cursor: pointer;
}


.custom-check-label.active {
	input:checked + .checkmark {
		border-color: $brand-color1;
		&::after {
			border-color: $brand-color1;
		}
	}
}

body {
	.list,
	.table-responsive-xl {
		/* width */
		&::-webkit-scrollbar {
			width: 7px;
			height: 7px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 3px $gray-300;
			border-radius: 10px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: $gray-400;
			border-radius: 10px;
		}
	}
}

.list {
	overflow-x: auto;
	margin-bottom: 0;
	padding-bottom: 1rem;
}


// Gets rid of flickering on hover of icons.
.tooltip {
	pointer-events: none;

	.tooltip-inner {
		min-width: $tooltip-min-width;
	}
}

.row {
	margin-right: ($grid-gutter-width / -2);
	margin-left: ($grid-gutter-width / -2);
}

.pagination .page-item.active {
    position: relative;
}

.pagination .page-item.active::before {
    width: calc(100% - #{$pagination-border-width});
    height: calc(100% - (#{$pagination-border-width} * 2));
    position: absolute;
    top: $pagination-border-width;
    left: 0px;
    background: $light-hover;
    content: "";
    z-index: 100;
}
