@import "dependencies";

@mixin ppio-button($color, $outline) {
	&,
	&:hover,
	&:focus,
	&.focus,
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle
	{
		color: if($outline == 'true' , $color ,$white);
		background: if($outline == 'true' , transparent ,$color);
		border-color: $color;
	}

	&:hover {
		// this box-shadow, as strange as it is to use a box-shadow for this, is used to lighten buttons on hover
		box-shadow: inset 0 0 200px 200px $light-hover;
	}

	&:focus,
	&.focus {
		// this box-shadow, as strange as it is to use a box-shadow for this, is used to lighten buttons as well as add a darker "border" inset
		box-shadow: inset 0 0 200px 200px $light-hover,
					inset 0 0 0 $btn-focus-width rgba(0, 0, 0, 0.28);
		// bootrap adds a border color here, need to unset it
		border-color: transparent;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background: #e6e6e6;
		border-color: #e6e6e6;
	}


	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		box-shadow: inset 0 0 200px 200px $light-hover;
		border-color: #888;

		&:focus {
			// this box-shadow, as strange as it is to use a box-shadow for this, is used to darken buttons on focus
			box-shadow: inset 0 0 200px 200px $dark-hover;
		}
	}
}

.btn {
	text-transform: none;
	letter-spacing: 0;
	white-space: nowrap; // restores previous bootstrap behavior
	&.btn-clear {
		box-shadow: none;
		border: 0 none;
		outline: none;
	}
}

.btn-icon {
	background-color: transparent;
	padding: 0;
	line-height: 1;
}

.btn-link {
	height: auto;
	line-height: 1;
	padding: 0;
	text-transform: none;
	color: $brand-color1;
	text-decoration: underline !important;
}

.btn-warning,
.btn-secondary {
	color: $white;
	&:hover {
		color: $white;
	}
}

.btn-outline-secondary:hover {
	color: $white;
}

// override the button colors since we can't use variables in them by default
@each $color, $value in $ppio-theme-colors {
	.btn-#{$color} {
		@include ppio-button($value, '');
	}
	.btn-outline-#{$color} {
		@include ppio-button($value, 'true');
	}
}
