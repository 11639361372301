// Bootstrap Variable Overrides
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1441px,
);

$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px,
);

$xxl-min-width: 1441px;
$xl-min-width: 1200px;
$lg-min-width: 992px;
$md-min-width: 768px;
$sm-min-width: 576px;

$modal-lg: 800px;
$modal-md: 50rem;
$modal-sm: 475px;

// Colors

$basic: #00ccd5;
$plus: #005471;
$enterprise: #002874;
$business: #4d5e73;

$light-green: #15c121;

$disabled: #e9ecef;

$gray-100: #f4f4f4;
$gray-200: #ededed;
$gray-300: #e6e6e6;
$gray-400: #bec2c6;
$gray-500: #93999f;
$gray-600: #777777;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #27323f;

$red: #e83333;
$green: #69e9a2;
$dark-green: #1f9b27;
$blue: #00a3da;
$blue-purple: #00a3da;
$orange: #ffb641;
$cyan: #00d6bd;
$cyan-800: #00e0c6;
$hubspot: #ff7a59;
$salesforce: #00a1df;
$paypal: #00457c;

$status-dropdown-dark: #31465f;
$status-dropdown-light: #506277;

$danger: #751c16;
$warning: $orange;
$info: $gray-400;
$white: #ffffff;
$body-color: $gray-900;

$branding-green: #2ec2b0;
$branding-body: #4a4a4a;
$branding-light-body: #7e7e7e;
$branding-purple: rgba(108, 80, 211, 1.00);
$branding-blue: #417ce4;
$branding-light-blue: #00b6ff;
$branding-pink: #d03bb0;

$theme-colors: ();
$theme-colors: (
	"normal": $gray-900,
	"green": $green,
	"medium": $gray-400,
	"light-blue": $branding-light-blue,
	"purple": $branding-purple,
	"orange": $orange,
	"red": $red,
	"branding": $branding-body,
	"dark-green": $dark-green,
	"light-gray": $gray-400,
	"dark-gray": $gray-600,
	"branding-purple": $branding-purple,
	"branding-blue": $branding-blue,
	"branding-light-blue": $branding-light-blue,
	"branding-green": $branding-green,
);




/***********************/
//       NEW vars      //
/***********************/
$brand-color1: var(--brand-color1);
$brand-color2: var(--brand-color2);
$brand-color3: var(--brand-color3);
$brand-color4: var(--brand-color4);

$action-color: var(--action-color);
$success-color: var(--success-color);
$warning-color: var(--warning-color);

$near-black: #454545;
$bg-color: #F4F4F4;
$inactive-icon-gray: #E6E6E6;
$inactive-text-gray: #C6C6C6;
$neutral-gray: #A6A6A6;
$increase-green: #1CB42E;
$decrease-red: #E54444;
$bg-inner-color: #FCFCFC;
$body-color: $near-black;

// $public-form-fields-text: #f00;
// $public-warning-text: #0f0;
// $public-background: ;

$dark-hover: rgba(0, 0, 0, .18);
$light-hover: rgba(255, 255, 255, .18);

$ppio-theme-colors: (
	"primary": $action-color,
	"secondary": $brand-color1,
	"success": $success-color,
	"info": $action-color,
	"warning": $warning-color,
	"danger": $warning-color,
	"neutral": $neutral-gray,
);

// Grid Styles
$grid-gutter-width-base: 1rem;
$grid-gutter-width: $grid-gutter-width-base;

// Btn Styling
$btn-border-radius: 0;
$btn-padding-x: 20px;
$btn-font-weight: 700;

// Form Styling
$input-btn-padding-x: 1rem;
$input-btn-padding-y: 0.438rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-padding-y-lg: 0.75rem;

$input-border-color: $gray-300;


$form-group-margin-bottom: 0.5rem;

// Font Styling
$h1-font-size: 30px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$headings-font-weight: 700;

$small-font-size: 0.875rem;
$large-font-size: 1.125rem;

// Element Styling
$border-radius: 0.125rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.125rem;

// Navbar Styling
$navbar-padding-y: 20px;
$navbar-padding-x: 30px;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

// Nav Items Styling
$nav-pills-border-radius: 10000px; // basically maxiumum height so it maxes out at the height of the element
// $nav-pills-link-active-color: $white;
// $nav-pills-link-active-bg: rgba($white, 0.15);

// Cards
$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-width: 0;
$card-border-radius: 0;
$card-inner-border-radius: 0;
$card-cap-bg: rgba($near-black, 0.03);
$card-bg: $white;

$card-img-overlay-padding: 1.25rem;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;


$assets-url: "/assets/" !default;
$fonts-url: $assets-url + "fonts/" !default;

// Pagination
$pagination-color: $near-black;
$pagination-bg: $white;
$pagination-border-width: 1px;
$pagination-border-color: $inactive-icon-gray;

$pagination-hover-color: $pagination-color;
$pagination-hover-bg: $pagination-bg;
$pagination-hover-border-color: $pagination-border-color;

$pagination-active-color: $white;
$pagination-active-bg: $brand-color1;
$pagination-active-border-color: $brand-color1;

$pagination-disabled-color: $inactive-icon-gray;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $pagination-disabled-color;

// Tables
$table-cell-padding: 0.5rem;
$table-cell-padding-sm: 0.3rem;

$asset-max-width: 250px;



/***********************/
// bootstrap overrides //
/***********************/
$component-active-bg: $action-color;
$custom-control-indicator-active-bg: $component-active-bg;
$custom-range-thumb-active-bg: $component-active-bg;
$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

// $input-focus-bg: transparent;
$input-focus-border-color: $gray-300;
$input-focus-color: #1a1a1a;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-500;

$custom-select-padding-x: 1rem;
$custom-select-border-radius: 0;
$custom-select-focus-box-shadow: $input-btn-focus-box-shadow;

$tooltip-min-width: 200px;
$tooltip-max-width: 400px;

@import "./fonts/variables";
