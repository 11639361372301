@import "./variables";

/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 45px;
	height: 25px;
	input {
		display: none;
		&:checked {
			+ .slider {
				background-color: $action-color;
				&::before {
					-webkit-transform: translateX(20px);
					-ms-transform: translateX(20px);
					transform: translateX(20px);
				}
			}
		}
		&:focus + .slider {
			box-shadow: 0 0 1px $action-color;
		}
		&:disabled + .slider {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
	/* The slider */
	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $gray-400;
		-webkit-transition: .4s;
		transition: .4s;
		&::before {
			position: absolute;
			content: "";
			height: 18px;
			width: 18px;
			left: 4px;
			bottom: 4px;
			background-color: $white;
			-webkit-transition: .4s;
			transition: .4s;
		}
		&.round {
			border-radius: 25px;
			&::before {
				border-radius: 50%;
			}
		}
	}
}

.input-group {
	margin-bottom: 0.5rem;
}


.form-group-wrap {
	input {
		border: 0 none;
	}
	.input-group-append span {
		border: 0 none;
		background: #fff;
	}
}


.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
	border-radius: 0;
}


.header-buttons-container .btn {
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}

.input-error {
	border-color: $red;
}
