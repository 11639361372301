@import "variables";

$icomoon-font-family: "ppicons";
$icomoon-font-path: $fonts-url + "ppicons";

$ppicon-add-shopping-cart: "\e941";
$ppicon-add: "\e942";
$ppicon-archive: "\e943";
$ppicon-arrow-back-left: "\e944";
$ppicon-arrow-downward: "\e945";
$ppicon-arrow-drop-down: "\e946";
$ppicon-arrow-forward-right: "\e947";
$ppicon-arrow-upward: "\e948";
$ppicon-assignement: "\e949";
$ppicon-attach-file: "\e94a";
$ppicon-attach-money: "\e94b";
$ppicon-attachment-sideways: "\e94c";
$ppicon-build-circle: "\e94d";
$ppicon-build: "\e94e";
$ppicon-calendar-month: "\e94f";
$ppicon-calendar-today: "\e950";
$ppicon-campaign-megaphone-filled: "\e951";
$ppicon-campaign: "\e952";
$ppicon-cell-tower: "\e953";
$ppicon-change: "\e954";
$ppicon-check-box-blank: "\e955";
$ppicon-check-box: "\e956";
$ppicon-check-circle: "\e957";
$ppicon-check: "\e958";
$ppicon-chevron-left: "\e959";
$ppicon-chevron-right: "\e95a";
$ppicon-close-x: "\e95b";
$ppicon-content-copy: "\e95c";
$ppicon-csv: "\e95d";
$ppicon-currency-exchange: "\e95e";
$ppicon-dashboard-filled: "\e95f";
$ppicon-dashboard-unfilled: "\e960";
$ppicon-data-table: "\e961";
$ppicon-delete: "\e962";
$ppicon-description: "\e963";
$ppicon-diamond: "\e964";
$ppicon-download: "\e965";
$ppicon-favorite: "\e96c";
$ppicon-file-save: "\e96d";
$ppicon-filter-list: "\e96e";
$ppicon-first-page-arrow: "\e96f";
$ppicon-flag: "\e970";
$ppicon-folder-closed: "\e971";
$ppicon-folder-open-filled: "\e972";
$ppicon-folder-open: "\e973";
$ppicon-forward-to-inbox: "\e974";
$ppicon-grid-view: "\e975";
$ppicon-group-filled: "\e976";
$ppicon-group-unfilled: "\e977";
$ppicon-handshake-filled: "\e978";
$ppicon-handshake: "\e979";
$ppicon-handyman: "\e97a";
$ppicon-high-quality: "\e97b";
$ppicon-hotel-class: "\e97c";
$ppicon-house: "\e97d";
$ppicon-hub: "\e97e";
$ppicon-image: "\e97f";
$ppicon-info: "\e980";
$ppicon-ink-pen: "\e981";
$ppicon-intermediate-check-box-dash: "\e982";
$ppicon-language: "\e983";
$ppicon-last-page-arrow: "\e984";
$ppicon-leaderboard: "\e985";
$ppicon-library-add: "\e986";
$ppicon-link: "\e987";
$ppicon-list: "\e988";
$ppicon-live-help: "\e989";
$ppicon-location-on: "\e98a";
$ppicon-mail: "\e98b";
$ppicon-map-filled: "\e98c";
$ppicon-map: "\e98d";
$ppicon-menu-book: "\e98e";
$ppicon-menu: "\e98f";
$ppicon-partner-exchange: "\e996";
$ppicon-pause: "\e997";
$ppicon-payments: "\e998";
$ppicon-percent: "\e999";
$ppicon-person-add: "\e99a";
$ppicon-person-filled: "\e99b";
$ppicon-person-unfilled: "\e99c";
$ppicon-phone-forwarded: "\e99d";
$ppicon-picture-as-pdf: "\e99e";
$ppicon-play-arrow: "\e99f";
$ppicon-potted-plant-filled: "\e9a0";
$ppicon-potted-plant-unfilled: "\e9a1";
$ppicon-publish: "\e9a2";
$ppicon-radio-button-checked: "\e9a3";
$ppicon-radio-button-unchecked: "\e9a4";
$ppicon-readiness-score-dashboard-filled: "\e9a5";
$ppicon-readiness-score-dashboard: "\e9a6";
$ppicon-redeem: "\e9a7";
$ppicon-relevize-logo-icon: "\e9a8";
$ppicon-reply: "\e9a9";
$ppicon-save: "\e9aa";
$ppicon-scan-delete: "\e9ab";
$ppicon-schedule: "\e9ac";
$ppicon-search: "\e9ad";
$ppicon-security: "\e9ae";
$ppicon-settings-gear-filled: "\e9af";
$ppicon-settings-gear-unfilled: "\e9b0";
$ppicon-share: "\e9b1";
$ppicon-show-chart: "\e9b2";
$ppicon-signature: "\e9b3";
$ppicon-smart-display: "\e9b4";
$ppicon-smartphone: "\e9b5";
$ppicon-social-leaderboard: "\e9b6";
$ppicon-speed-filled: "\e9b7";
$ppicon-speed-unfilled: "\e9b8";
$ppicon-stacked-email: "\e9b9";
$ppicon-table: "\e9c0";
$ppicon-task: "\e9c1";
$ppicon-thumb-up: "\e9c2";
$ppicon-toys-and-games: "\e9c3";
$ppicon-trophy: "\e9c4";
$ppicon-undo: "\e9c5";
$ppicon-unsubscribe: "\e9c6";
$ppicon-upload-file: "\e9c7";
$ppicon-upload: "\e9c8";
$ppicon-verified-user: "\e9c9";
$ppicon-visibility: "\e9ca";
$ppicon-vital-signs: "\e9cb";
$ppicon-warning: "\e9cc";
$ppicon-web-traffic: "\e9cd";
$ppicon-work: "\e9ce";
$ppicon-zoom-in: "\e9cf";
$ppicon-draft: "\e966";
$ppicon-drag-handle: "\e967";
$ppicon-edit-pencil: "\e968";
$ppicon-error-circle: "\e969";
$ppicon-event-available: "\e96a";
$ppicon-event: "\e96b";
$ppicon-monitor-heart-pulse-filled: "\e990";
$ppicon-monitor-heart: "\e991";
$ppicon-more-vert: "\e992";
$ppicon-note-add: "\e993";
$ppicon-open-in-new: "\e994";
$ppicon-paid: "\e995";
$ppicon-star: "\e9ba";
$ppicon-stop-hand: "\e9bb";
$ppicon-storefront-filled: "\e9bc";
$ppicon-storefront-unfilled: "\e9bd";
$ppicon-table-chart-view-filled: "\e9be";
$ppicon-table-chart-view-unfilled: "\e9bf";
$ppicon-link1: "\e940";
$ppicon-analytics: "\e92b";
$ppicon-file-video: "\e92c";
$ppicon-file-pdf: "\e93d";
$ppicon-file-jpg: "\e93e";
$ppicon-file-zip: "\e93f";
$ppicon-account: "\e916";
$ppicon-affiliatetracking: "\e917";
$ppicon-arrow-circle-right: "\e918";
$ppicon-arrow-dow-line: "\e919";
$ppicon-arrow-down-fill: "\e91a";
$ppicon-arrow-left: "\e91b";
$ppicon-arrow-right: "\e91c";
$ppicon-arrow-up-fill: "\e91d";
$ppicon-arrow-up-line: "\e91e";
$ppicon-category-folder: "\e91f";
$ppicon-checkmark: "\e920";
$ppicon-circle-arrow-left: "\e921";
$ppicon-circle-minus: "\e922";
$ppicon-circle-plus: "\e923";
$ppicon-circle: "\e924";
$ppicon-close: "\e925";
$ppicon-copyright: "\e926";
$ppicon-dollar: "\e927";
$ppicon-exclaimation: "\e928";
$ppicon-eyedropper: "\e929";
$ppicon-file-folder: "\e92a";
$ppicon-hamburger: "\e92d";
$ppicon-icon-file-image: "\e92e";
$ppicon-info1: "\e92f";
$ppicon-integrations: "\e930";
$ppicon-lock: "\e931";
$ppicon-minus: "\e932";
$ppicon-money: "\e933";
$ppicon-paypal-path1: "\e934";
$ppicon-paypal-path2: "\e935";
$ppicon-plug: "\e936";
$ppicon-plus: "\e937";
$ppicon-question: "\e938";
$ppicon-signout: "\e939";
$ppicon-spinner: "\e93a";
$ppicon-support: "\e93b";
$ppicon-trash: "\e93c";
$ppicon-add1: "\e900";
$ppicon-affiliate-tracking: "\e901";
$ppicon-announcements: "\e902";
$ppicon-dashboard: "\e903";
$ppicon-delete1: "\e904";
$ppicon-directory: "\e905";
$ppicon-download1: "\e906";
$ppicon-edit: "\e907";
$ppicon-external-link: "\e908";
$ppicon-integration: "\e909";
$ppicon-leads: "\e90a";
$ppicon-learning-center: "\e90b";
$ppicon-logout: "\e90c";
$ppicon-my-account: "\e90d";
$ppicon-opportunities: "\e90e";
$ppicon-partners: "\e90f";
$ppicon-payments1: "\e910";
$ppicon-program: "\e911";
$ppicon-program-terms: "\e912";
$ppicon-resource-center: "\e913";
$ppicon-search1: "\e914";
$ppicon-settings: "\e915";

@import "variables";

@font-face {
	font-family: '#{$icomoon-font-family}';
	src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?l0wywb') format('woff2'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?l0wywb') format('truetype'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?l0wywb') format('woff'),
		url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?l0wywb##{$icomoon-font-family}') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.ppicon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ppicon-add-shopping-cart {
	&:before {
		content: $ppicon-add-shopping-cart;
	}
}

.ppicon-add {
	&:before {
		content: $ppicon-add;
	}
}

.ppicon-archive {
	&:before {
		content: $ppicon-archive;
	}
}

.ppicon-arrow-back-left {
	&:before {
		content: $ppicon-arrow-back-left;
	}
}

.ppicon-arrow-downward {
	&:before {
		content: $ppicon-arrow-downward;
	}
}

.ppicon-arrow-drop-down {
	&:before {
		content: $ppicon-arrow-drop-down;
	}
}

.ppicon-arrow-forward-right {
	&:before {
		content: $ppicon-arrow-forward-right;
	}
}

.ppicon-arrow-upward {
	&:before {
		content: $ppicon-arrow-upward;
	}
}

.ppicon-assignement {
	&:before {
		content: $ppicon-assignement;
	}
}

.ppicon-attach-file {
	&:before {
		content: $ppicon-attach-file;
	}
}

.ppicon-attach-money {
	&:before {
		content: $ppicon-attach-money;
	}
}

.ppicon-attachment-sideways {
	&:before {
		content: $ppicon-attachment-sideways;
	}
}

.ppicon-build-circle {
	&:before {
		content: $ppicon-build-circle;
	}
}

.ppicon-build {
	&:before {
		content: $ppicon-build;
	}
}

.ppicon-calendar-month {
	&:before {
		content: $ppicon-calendar-month;
	}
}

.ppicon-calendar-today {
	&:before {
		content: $ppicon-calendar-today;
	}
}

.ppicon-campaign-megaphone-filled {
	&:before {
		content: $ppicon-campaign-megaphone-filled;
	}
}

.ppicon-campaign {
	&:before {
		content: $ppicon-campaign;
	}
}

.ppicon-cell-tower {
	&:before {
		content: $ppicon-cell-tower;
	}
}

.ppicon-change {
	&:before {
		content: $ppicon-change;
	}
}

.ppicon-check-box-blank {
	&:before {
		content: $ppicon-check-box-blank;
	}
}

.ppicon-check-box {
	&:before {
		content: $ppicon-check-box;
	}
}

.ppicon-check-circle {
	&:before {
		content: $ppicon-check-circle;
	}
}

.ppicon-check {
	&:before {
		content: $ppicon-check;
	}
}

.ppicon-chevron-left {
	&:before {
		content: $ppicon-chevron-left;
	}
}

.ppicon-chevron-right {
	&:before {
		content: $ppicon-chevron-right;
	}
}

.ppicon-close-x {
	&:before {
		content: $ppicon-close-x;
	}
}

.ppicon-content-copy {
	&:before {
		content: $ppicon-content-copy;
	}
}

.ppicon-csv {
	&:before {
		content: $ppicon-csv;
	}
}

.ppicon-currency-exchange {
	&:before {
		content: $ppicon-currency-exchange;
	}
}

.ppicon-dashboard-filled {
	&:before {
		content: $ppicon-dashboard-filled;
	}
}

.ppicon-dashboard-unfilled {
	&:before {
		content: $ppicon-dashboard-unfilled;
	}
}

.ppicon-data-table {
	&:before {
		content: $ppicon-data-table;
	}
}

.ppicon-delete {
	&:before {
		content: $ppicon-delete;
	}
}

.ppicon-description {
	&:before {
		content: $ppicon-description;
	}
}

.ppicon-diamond {
	&:before {
		content: $ppicon-diamond;
	}
}

.ppicon-download {
	&:before {
		content: $ppicon-download;
	}
}

.ppicon-favorite {
	&:before {
		content: $ppicon-favorite;
	}
}

.ppicon-file-save {
	&:before {
		content: $ppicon-file-save;
	}
}

.ppicon-filter-list {
	&:before {
		content: $ppicon-filter-list;
	}
}

.ppicon-first-page-arrow {
	&:before {
		content: $ppicon-first-page-arrow;
	}
}

.ppicon-flag {
	&:before {
		content: $ppicon-flag;
	}
}

.ppicon-folder-closed {
	&:before {
		content: $ppicon-folder-closed;
	}
}

.ppicon-folder-open-filled {
	&:before {
		content: $ppicon-folder-open-filled;
	}
}

.ppicon-folder-open {
	&:before {
		content: $ppicon-folder-open;
	}
}

.ppicon-forward-to-inbox {
	&:before {
		content: $ppicon-forward-to-inbox;
	}
}

.ppicon-grid-view {
	&:before {
		content: $ppicon-grid-view;
	}
}

.ppicon-group-filled {
	&:before {
		content: $ppicon-group-filled;
	}
}

.ppicon-group-unfilled {
	&:before {
		content: $ppicon-group-unfilled;
	}
}

.ppicon-handshake-filled {
	&:before {
		content: $ppicon-handshake-filled;
	}
}

.ppicon-handshake {
	&:before {
		content: $ppicon-handshake;
	}
}

.ppicon-handyman {
	&:before {
		content: $ppicon-handyman;
	}
}

.ppicon-high-quality {
	&:before {
		content: $ppicon-high-quality;
	}
}

.ppicon-hotel-class {
	&:before {
		content: $ppicon-hotel-class;
	}
}

.ppicon-house {
	&:before {
		content: $ppicon-house;
	}
}

.ppicon-hub {
	&:before {
		content: $ppicon-hub;
	}
}

.ppicon-image {
	&:before {
		content: $ppicon-image;
	}
}

.ppicon-info {
	&:before {
		content: $ppicon-info;
	}
}

.ppicon-ink-pen {
	&:before {
		content: $ppicon-ink-pen;
	}
}

.ppicon-intermediate-check-box-dash {
	&:before {
		content: $ppicon-intermediate-check-box-dash;
	}
}

.ppicon-language {
	&:before {
		content: $ppicon-language;
	}
}

.ppicon-last-page-arrow {
	&:before {
		content: $ppicon-last-page-arrow;
	}
}

.ppicon-leaderboard {
	&:before {
		content: $ppicon-leaderboard;
	}
}

.ppicon-library-add {
	&:before {
		content: $ppicon-library-add;
	}
}

.ppicon-link {
	&:before {
		content: $ppicon-link;
	}
}

.ppicon-list {
	&:before {
		content: $ppicon-list;
	}
}

.ppicon-live-help {
	&:before {
		content: $ppicon-live-help;
	}
}

.ppicon-location-on {
	&:before {
		content: $ppicon-location-on;
	}
}

.ppicon-mail {
	&:before {
		content: $ppicon-mail;
	}
}

.ppicon-map-filled {
	&:before {
		content: $ppicon-map-filled;
	}
}

.ppicon-map {
	&:before {
		content: $ppicon-map;
	}
}

.ppicon-menu-book {
	&:before {
		content: $ppicon-menu-book;
	}
}

.ppicon-menu {
	&:before {
		content: $ppicon-menu;
	}
}

.ppicon-partner-exchange {
	&:before {
		content: $ppicon-partner-exchange;
	}
}

.ppicon-pause {
	&:before {
		content: $ppicon-pause;
	}
}

.ppicon-payments {
	&:before {
		content: $ppicon-payments;
	}
}

.ppicon-percent {
	&:before {
		content: $ppicon-percent;
	}
}

.ppicon-person-add {
	&:before {
		content: $ppicon-person-add;
	}
}

.ppicon-person-filled {
	&:before {
		content: $ppicon-person-filled;
	}
}

.ppicon-person-unfilled {
	&:before {
		content: $ppicon-person-unfilled;
	}
}

.ppicon-phone-forwarded {
	&:before {
		content: $ppicon-phone-forwarded;
	}
}

.ppicon-picture-as-pdf {
	&:before {
		content: $ppicon-picture-as-pdf;
	}
}

.ppicon-play-arrow {
	&:before {
		content: $ppicon-play-arrow;
	}
}

.ppicon-potted-plant-filled {
	&:before {
		content: $ppicon-potted-plant-filled;
	}
}

.ppicon-potted-plant-unfilled {
	&:before {
		content: $ppicon-potted-plant-unfilled;
	}
}

.ppicon-publish {
	&:before {
		content: $ppicon-publish;
	}
}

.ppicon-radio-button-checked {
	&:before {
		content: $ppicon-radio-button-checked;
	}
}

.ppicon-radio-button-unchecked {
	&:before {
		content: $ppicon-radio-button-unchecked;
	}
}

.ppicon-readiness-score-dashboard-filled {
	&:before {
		content: $ppicon-readiness-score-dashboard-filled;
	}
}

.ppicon-readiness-score-dashboard {
	&:before {
		content: $ppicon-readiness-score-dashboard;
	}
}

.ppicon-redeem {
	&:before {
		content: $ppicon-redeem;
	}
}

.ppicon-relevize-logo-icon {
	&:before {
		content: $ppicon-relevize-logo-icon;
	}
}

.ppicon-reply {
	&:before {
		content: $ppicon-reply;
	}
}

.ppicon-save {
	&:before {
		content: $ppicon-save;
	}
}

.ppicon-scan-delete {
	&:before {
		content: $ppicon-scan-delete;
	}
}

.ppicon-schedule {
	&:before {
		content: $ppicon-schedule;
	}
}

.ppicon-search {
	&:before {
		content: $ppicon-search;
	}
}

.ppicon-security {
	&:before {
		content: $ppicon-security;
	}
}

.ppicon-settings-gear-filled {
	&:before {
		content: $ppicon-settings-gear-filled;
	}
}

.ppicon-settings-gear-unfilled {
	&:before {
		content: $ppicon-settings-gear-unfilled;
	}
}

.ppicon-share {
	&:before {
		content: $ppicon-share;
	}
}

.ppicon-show-chart {
	&:before {
		content: $ppicon-show-chart;
	}
}

.ppicon-signature {
	&:before {
		content: $ppicon-signature;
	}
}

.ppicon-smart-display {
	&:before {
		content: $ppicon-smart-display;
	}
}

.ppicon-smartphone {
	&:before {
		content: $ppicon-smartphone;
	}
}

.ppicon-social-leaderboard {
	&:before {
		content: $ppicon-social-leaderboard;
	}
}

.ppicon-speed-filled {
	&:before {
		content: $ppicon-speed-filled;
	}
}

.ppicon-speed-unfilled {
	&:before {
		content: $ppicon-speed-unfilled;
	}
}

.ppicon-stacked-email {
	&:before {
		content: $ppicon-stacked-email;
	}
}

.ppicon-table {
	&:before {
		content: $ppicon-table;
	}
}

.ppicon-task {
	&:before {
		content: $ppicon-task;
	}
}

.ppicon-thumb-up {
	&:before {
		content: $ppicon-thumb-up;
	}
}

.ppicon-toys-and-games {
	&:before {
		content: $ppicon-toys-and-games;
	}
}

.ppicon-trophy {
	&:before {
		content: $ppicon-trophy;
	}
}

.ppicon-undo {
	&:before {
		content: $ppicon-undo;
	}
}

.ppicon-unsubscribe {
	&:before {
		content: $ppicon-unsubscribe;
	}
}

.ppicon-upload-file {
	&:before {
		content: $ppicon-upload-file;
	}
}

.ppicon-upload {
	&:before {
		content: $ppicon-upload;
	}
}

.ppicon-verified-user {
	&:before {
		content: $ppicon-verified-user;
	}
}

.ppicon-visibility {
	&:before {
		content: $ppicon-visibility;
	}
}

.ppicon-vital-signs {
	&:before {
		content: $ppicon-vital-signs;
	}
}

.ppicon-warning {
	&:before {
		content: $ppicon-warning;
	}
}

.ppicon-web-traffic {
	&:before {
		content: $ppicon-web-traffic;
	}
}

.ppicon-work {
	&:before {
		content: $ppicon-work;
	}
}

.ppicon-zoom-in {
	&:before {
		content: $ppicon-zoom-in;
	}
}

.ppicon-draft {
	&:before {
		content: $ppicon-draft;
	}
}

.ppicon-drag-handle {
	&:before {
		content: $ppicon-drag-handle;
	}
}

.ppicon-edit-pencil {
	&:before {
		content: $ppicon-edit-pencil;
	}
}

.ppicon-error-circle {
	&:before {
		content: $ppicon-error-circle;
	}
}

.ppicon-event-available {
	&:before {
		content: $ppicon-event-available;
	}
}

.ppicon-event {
	&:before {
		content: $ppicon-event;
	}
}

.ppicon-monitor-heart-pulse-filled {
	&:before {
		content: $ppicon-monitor-heart-pulse-filled;
	}
}

.ppicon-monitor-heart {
	&:before {
		content: $ppicon-monitor-heart;
	}
}

.ppicon-more-vert {
	&:before {
		content: $ppicon-more-vert;
	}
}

.ppicon-note-add {
	&:before {
		content: $ppicon-note-add;
	}
}

.ppicon-open-in-new {
	&:before {
		content: $ppicon-open-in-new;
	}
}

.ppicon-paid {
	&:before {
		content: $ppicon-paid;
	}
}

.ppicon-star {
	&:before {
		content: $ppicon-star;
	}
}

.ppicon-stop-hand {
	&:before {
		content: $ppicon-stop-hand;
	}
}

.ppicon-storefront-filled {
	&:before {
		content: $ppicon-storefront-filled;
	}
}

.ppicon-storefront-unfilled {
	&:before {
		content: $ppicon-storefront-unfilled;
	}
}

.ppicon-table-chart-view-filled {
	&:before {
		content: $ppicon-table-chart-view-filled;
	}
}

.ppicon-table-chart-view-unfilled {
	&:before {
		content: $ppicon-table-chart-view-unfilled;
	}
}

.ppicon-link1 {
	&:before {
		content: $ppicon-link1;
	}
}

.ppicon-analytics {
	&:before {
		content: $ppicon-analytics;
	}
}

.ppicon-file-video {
	&:before {
		content: $ppicon-file-video;
	}
}

.ppicon-file-pdf {
	&:before {
		content: $ppicon-file-pdf;
	}
}

.ppicon-file-jpg {
	&:before {
		content: $ppicon-file-jpg;
	}
}

.ppicon-file-zip {
	&:before {
		content: $ppicon-file-zip;
	}
}

.ppicon-account {
	&:before {
		content: $ppicon-account;
	}
}

.ppicon-affiliatetracking {
	&:before {
		content: $ppicon-affiliatetracking;
	}
}

.ppicon-arrow-circle-right {
	&:before {
		content: $ppicon-arrow-circle-right;
	}
}

.ppicon-arrow-dow-line {
	&:before {
		content: $ppicon-arrow-dow-line;
	}
}

.ppicon-arrow-down-fill {
	&:before {
		content: $ppicon-arrow-down-fill;
	}
}

.ppicon-arrow-left {
	&:before {
		content: $ppicon-arrow-left;
	}
}

.ppicon-arrow-right {
	&:before {
		content: $ppicon-arrow-right;
	}
}

.ppicon-arrow-up-fill {
	&:before {
		content: $ppicon-arrow-up-fill;
	}
}

.ppicon-arrow-up-line {
	&:before {
		content: $ppicon-arrow-up-line;
	}
}

.ppicon-category-folder {
	&:before {
		content: $ppicon-category-folder;
	}
}

.ppicon-checkmark {
	&:before {
		content: $ppicon-checkmark;
	}
}

.ppicon-circle-arrow-left {
	&:before {
		content: $ppicon-circle-arrow-left;
	}
}

.ppicon-circle-minus {
	&:before {
		content: $ppicon-circle-minus;
	}
}

.ppicon-circle-plus {
	&:before {
		content: $ppicon-circle-plus;
	}
}

.ppicon-circle {
	&:before {
		content: $ppicon-circle;
	}
}

.ppicon-close {
	&:before {
		content: $ppicon-close;
	}
}

.ppicon-copyright {
	&:before {
		content: $ppicon-copyright;
	}
}

.ppicon-dollar {
	&:before {
		content: $ppicon-dollar;
	}
}

.ppicon-exclaimation {
	&:before {
		content: $ppicon-exclaimation;
	}
}

.ppicon-eyedropper {
	&:before {
		content: $ppicon-eyedropper;
	}
}

.ppicon-file-folder {
	&:before {
		content: $ppicon-file-folder;
	}
}

.ppicon-hamburger {
	&:before {
		content: $ppicon-hamburger;
	}
}

.ppicon-icon-file-image {
	&:before {
		content: $ppicon-icon-file-image;
	}
}

.ppicon-info1 {
	&:before {
		content: $ppicon-info1;
	}
}

.ppicon-integrations {
	&:before {
		content: $ppicon-integrations;
	}
}

.ppicon-lock {
	&:before {
		content: $ppicon-lock;
	}
}

.ppicon-minus {
	&:before {
		content: $ppicon-minus;
	}
}

.ppicon-money {
	&:before {
		content: $ppicon-money;
	}
}

.ppicon-paypal .path1 {
	&:before {
		content: $ppicon-paypal-path1;
	}
}

.ppicon-paypal .path2 {
	&:before {
		content: $ppicon-paypal-path2;
		margin-left: -0.88671875em;
		opacity: 0.7;
	}
}

.ppicon-plug {
	&:before {
		content: $ppicon-plug;
	}
}

.ppicon-plus {
	&:before {
		content: $ppicon-plus;
	}
}

.ppicon-question {
	&:before {
		content: $ppicon-question;
	}
}

.ppicon-signout {
	&:before {
		content: $ppicon-signout;
	}
}

.ppicon-spinner {
	&:before {
		content: $ppicon-spinner;
	}
}

.ppicon-support {
	&:before {
		content: $ppicon-support;
	}
}

.ppicon-trash {
	&:before {
		content: $ppicon-trash;
	}
}

.ppicon-add1 {
	&:before {
		content: $ppicon-add1;
	}
}

.ppicon-affiliate-tracking {
	&:before {
		content: $ppicon-affiliate-tracking;
	}
}

.ppicon-announcements {
	&:before {
		content: $ppicon-announcements;
	}
}

.ppicon-dashboard {
	&:before {
		content: $ppicon-dashboard;
	}
}

.ppicon-delete1 {
	&:before {
		content: $ppicon-delete1;
	}
}

.ppicon-directory {
	&:before {
		content: $ppicon-directory;
	}
}

.ppicon-download1 {
	&:before {
		content: $ppicon-download1;
	}
}

.ppicon-edit {
	&:before {
		content: $ppicon-edit;
	}
}

.ppicon-external-link {
	&:before {
		content: $ppicon-external-link;
	}
}

.ppicon-integration {
	&:before {
		content: $ppicon-integration;
	}
}

.ppicon-leads {
	&:before {
		content: $ppicon-leads;
	}
}

.ppicon-learning-center {
	&:before {
		content: $ppicon-learning-center;
	}
}

.ppicon-logout {
	&:before {
		content: $ppicon-logout;
	}
}

.ppicon-my-account {
	&:before {
		content: $ppicon-my-account;
	}
}

.ppicon-opportunities {
	&:before {
		content: $ppicon-opportunities;
	}
}

.ppicon-partners {
	&:before {
		content: $ppicon-partners;
	}
}

.ppicon-payments1 {
	&:before {
		content: $ppicon-payments1;
	}
}

.ppicon-program {
	&:before {
		content: $ppicon-program;
	}
}

.ppicon-program-terms {
	&:before {
		content: $ppicon-program-terms;
	}
}

.ppicon-resource-center {
	&:before {
		content: $ppicon-resource-center;
	}
}

.ppicon-search1 {
	&:before {
		content: $ppicon-search1;
	}
}

.ppicon-settings {
	&:before {
		content: $ppicon-settings;
	}
}
