.cursor-pointer {
	cursor: pointer;
}

.text-decoration-none {
	text-decoration: none !important;
}

.body-large {
	font-size: $large-font-size;
}
