$opacity-on-hover: .82;

// by default links are assumed to be dark on light
a,
.dark-on-light a {
	// when the content is on a light background
	color: $brand-color1;
	text-decoration: none;
	// .page-link is for pagination
	&:not(.btn):not(.page-link):not(.nav-link.active):hover {
		color: $brand-color1;
		opacity: $opacity-on-hover;
		text-decoration: none;
	}
}

.light-on-dark {
	color: $white;

	a {
		color: $white;
		text-decoration: underline;
		// .page-link is for pagination
		&:not(.btn):not(.page-link):hover {
			color: $white;
			text-decoration: underline;
			opacity: $opacity-on-hover;
		}
	}
}

a.link {
	cursor: pointer;
}
