// Set default sizes for icons
#pdf-icon {
	width: 52.5px;
	height: 70px;
}

#video-icon {
	width: 52.5px;
	height: 70px;
}

#jpg-icon {
	width: 52.5px;
	height: 70px;
}

#zip-icon {
	width: 52.5px;
	height: 70px;
}
