@import "dependencies";

// Make 5ths columns
.col-sm-5th {
	@include make-col-ready;
	@include media-breakpoint-up('sm') {
		@include make-col(2.4);
	}
}

.col-md-5th {
	@include make-col-ready;
	@include media-breakpoint-up('md') {
		@include make-col(2.4);
	}
}

.col-lg-5th {
	@include make-col-ready;
	@include media-breakpoint-up('lg') {
		@include make-col(2.4);
	}
}

.col-xl-5th {
	@include make-col-ready;
	@include media-breakpoint-up('xl') {
		@include make-col(2.4);
	}
}
